import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src430903205/src/dashbot-home-gatsby/src/layouts/blog/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Under the Behavior tab in the left-hand navigation panel, there is a new report called Escalation. This report enables you to analyze and reduce escalation conflicts within your chatbot experience. You’ll first need to select which conversational Intent you want to investigate. In this example, we took a closer look at the Intent named ‘SPEAK_TO_AGENT’ so we can understand how many users escalated the conversation and what caused them to escalate.`}</p>
    <p>{`The Summary tab shows you two metrics:`}</p>
    <ul>
      <li parentName="ul">{`Users Contained: Number of users who completed the selected Intent.`}</li>
      <li parentName="ul">{`Users Escalated: Number of users who did not complete the selected Intent.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/12/18142225/Escalation1.png",
        "alt": null
      }}></img><br parentName="p"></br>{`
`}<img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/12/18142931/Screen-Shot-2018-12-17-at-3.27.39-PM.png",
        "alt": null
      }}></img></p>
    <p>{`The Intents tab shows you all incoming and outgoing intents to configure your Escalation Report. In this example, the ‘HELLO’ Intent is excluded from the report, which means for all completions of SPEAK_TO_AGENT Intent, we don’t want to count users who triggered the HELLO Intent. We want to drill down to the users who actually had an issue and escalated to a human agent, not just anyone who greeted the bot.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/12/18142722/EscalationConfiguration.png",
        "alt": null
      }}></img></p>
    <p>{`We also want to define how many intents we’d like to limit the report to. Ten intents is a good benchmark to start with for this particular intent because on average, users have about 10 interactions with the chatbot before escalating to a human agent. The higher you turn the knob, the wider the net you cast.`}</p>
    <p><img parentName="p" {...{
        "src": "https://i.dashbot.io/wp-home/uploads/2018/12/18142851/EscalationsByDay1.png",
        "alt": null
      }}></img></p>
    <p>{`The Escalation Report is part of the Enterprise package so `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales"
      }}>{`contact us`}</a>{` to learn how many users have escalated and which conversational pathways cause them to escalate. Tell us about what you’re doing so we can help you `}<a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales"
      }}>{`here`}</a>{`!`}</p>
    <p><strong parentName="p">{`ABOUT DASHBOT`}</strong></p>
    <p><em parentName="p"><a parentName="em" {...{
          "href": "https://www.dashbot.io/"
        }}>{`Dashbot`}</a>{` is the leading conversational analytics platform that enables developers and brands to increase engagement, acquisition, and monetization through actionable insights. In addition to traditional analytics like engagement and retention, Dashbot provides bot and voice specific analytics and tools including detailed session transcripts, NLP optimization, and conversation paths. We also have interactive tools to take action on the data, like our live-person takeover, broadcast messaging, and audience builder.`}</em></p>
    <p><a parentName="p" {...{
        "href": "https://www.dashbot.io/contact/sales/?ref=blog+escalation+report"
      }}><br parentName="a"></br>
        {`Contact us for a demo `}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      